import React from "react";
import { useState } from "react";
import { Wrapper, VirtualMobile, ButtonWrapper, ListWrapper, Separator, FormFieldWrapper,LandingSlider,PhotosWrapper, CarrouselWrapper2, ImageWrapper, ArtistBioWrapper,ArtistImage, ArtistBio,Atelier,GifTile,CacheCache,Quote,QuoteWrapper, Slide, SlideImage,ImageArtistWrapper, ArtistBioText} from "./Epode.module.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import ANA from '../../../../res/monso/portrait.jpeg'
import MATHIAS from '../../../../res/bensimon/portrait.jpg'
import AnaOeuvre1 from '../../../../res/monso/ana-oeuvre-1.png'
import AnaOeuvre2 from '../../../../res/monso/ana-oeuvre-2.png'
import AnaOeuvre3 from '../../../../res/monso/ana-oeuvre-3.png'
import AnaOeuvre4 from '../../../../res/monso/ana-oeuvre-4.png'
import AnaOeuvre5 from '../../../../res/monso/ana-oeuvre-5.png'
import AnaOeuvre6 from '../../../../res/monso/ana-oeuvre-6.png'
import AnaOeuvre7 from '../../../../res/monso/ana-oeuvre-7.png'
import AnaAtelier1 from '../../../../res/monso/atelier/atelier-1.jpeg'
import AnaAtelier2 from '../../../../res/monso/atelier/atelier-2.jpeg'
import AnaAtelier3 from '../../../../res/monso/atelier/atelier-3.jpeg'
import MathiasOeuvre1 from '../../../../res/bensimon/mathias-oeuvre-1.png'
import MathiasOeuvre2 from '../../../../res/bensimon/mathias-oeuvre-2.png'
import MathiasOeuvre3 from '../../../../res/bensimon/mathias-oeuvre-3.png'
import MathiasOeuvre4 from '../../../../res/bensimon/mathias-oeuvre-4.png'
import MathiasOeuvre5 from '../../../../res/bensimon/mathias-oeuvre-5.png'
import MathiasOeuvre6 from '../../../../res/bensimon/mathias-oeuvre-6.png'
import MathiasOeuvre7 from '../../../../res/bensimon/mathias-oeuvre-7.png'
import MathiasAtelier1 from '../../../../res/bensimon/atelier/atelier-1.jpg'
import MathiasAtelier2 from '../../../../res/bensimon/atelier/atelier-2.jpg'
import MathiasAtelier3 from '../../../../res/bensimon/atelier/atelier-3.jpg'
import EPODE from "../../../../res/epode/epode-slide.jpg"
import EPODETILE from "../../../../res/epode/epode.jpg"
import SITUATION1 from "../../../../res/epode/situations/situation-1.jpg"
import SITUATION2 from "../../../../res/epode/situations/situation-2.jpg"
import SITUATION3 from "../../../../res/epode/situations/situation-3.jpg"
import SITUATION4 from "../../../../res/epode/situations/situation-4.jpg"
import SITUATION5 from "../../../../res/epode/situations/situation-5.jpg"
import CP from "../../../../res/epode/cp_epode.pdf"
import Title from "../../../../components/Title";
import CatalogueAna from '../../../../res/monso/catalogue_ana.pdf'
import CatalogueMathias from '../../../../res/bensimon/catalogue_mathias.pdf'


// import FeedInstagram from "../../../../components/FeedInstagram";


const Epode = () => {


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const responsiveArtist = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };


  const oeuvresANA = 
    {
      photos: [
        { src: AnaOeuvre1, artist: "Ana Monsó", title: "No es para tanto, 2023", dimension: "200 x 200 cm", copyright: "", style: "Acrylique, pastels, crayons, marqueurs et bombe aérosol sur toile de coton"},
        { src: AnaOeuvre2, artist: "Ana Monsó", title: "Cambio los planes, 2023", dimension: "200 x 200 cm", copyright: "", style: "Acrylique, pastels, crayons, marqueurs et bombe aérosol sur toile de coton"},
        { src: AnaOeuvre3, artist: "Ana Monsó", title: "Entre castillos y palmeras 2, 2022", dimension: "180 x 155 cm", copyright: "", style: "Acrylique, pastels, crayons, marqueurs et bombe aérosol sur toile de coton"},
        { src: AnaOeuvre4, artist: "Ana Monsó", title: "Esta bien asi, 2023", dimension: "146 x 114 cm", copyright: "", style: "Acrylique, pastels, crayons, marqueurs et bombe aérosol sur toile de coton"},
        { src: AnaOeuvre5, artist: "Ana Monsó", title: "Todo es un instante, 2023", dimension: "146 x 114 cm", copyright: "", style: "Acrylique, pastels, crayons, marqueurs et bombe aérosol sur toile de coton"},
        { src: AnaOeuvre6, artist: "Ana Monsó", title: "This is what it looks like, 2023", dimension: "116 x 89 cm", copyright: "", style: "Acrylique, pastels, crayons, marqueurs et bombe aérosol sur toile de coton"},
        { src: AnaOeuvre7, artist: "Ana Monsó", title: "Como eran las cosas, 2023", dimension: "130 x 162 cm", copyright: "", style: "Acrylique, pastels, crayons, marqueurs et bombe aérosol sur toile de coton"}
      ],
  };

  const oeuvresMATHIAS = 
  {
    photos: [
      { src: MathiasOeuvre1, artist: "Mathias Bensimon", title: "Vibration n°3, 2023", dimension: "200 x 140 cm", copyright: "", style: "Huile sur toile de coton"},
      { src: MathiasOeuvre2, artist: "Mathias Bensimon", title: "Sans Titre, 2022", dimension: "100 x 81 cm", copyright: "", style: "Huile sur toile de coton"},
      { src: MathiasOeuvre3, artist: "Mathias Bensimon", title: "Sans titre, 2022", dimension: "130 x 97 cm", copyright: "", style: "Huile sur toile de coton"},
      { src: MathiasOeuvre4, artist: "Mathias Bensimon", title: "Sans titre, 2022", dimension: "116 x 89 cm", copyright: "", style: "Huile sur toile de coton"},
      { src: MathiasOeuvre5, artist: "Mathias Bensimon", title: "Sans titre, 2022", dimension: "100 x 65 cm", copyright: "", style: "Huile sur toile de coton"},
      { src: MathiasOeuvre6, artist: "Mathias Bensimon", title: "Sans titre, 2022", dimension: "116 x 89 cm", copyright: "", style: "Huile sur toile de coton"},
      { src: MathiasOeuvre7, artist: "Mathias Bensimon", title: "Le ballet cosmique, 2023", dimension: "200 x 140cm", copyright: "", style: "Huile sur toile de coton"}
    ],
};

  // const [email, setEmail] = useState("");

  const [disp, setDisp] = useState(false);
  const [email, setEmail] = useState("");
  const [result, setResult] = useState(null);
  const [fields, setFields] = useState({
    FNAME: '',
    LNAME: ''
  })

  const swapDisp=()=> {
    setDisp((disp) => !disp);
    console.log("disp === " + disp);
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email, fields);
    setResult(result);
    console.log("result=>",result.result);
    // but you can do whatever you want
  }

  const handleEmail = async (e) => {
    e.preventDefault();

    setEmail(e.target.value);
    console.log("email=>".email);

  }
  const handleName = async (e) => {
    e.preventDefault();
    setFields({
      FNAME: e.target.value
    });
    console.log("fields=>".fields);
  }
  return (
    <div >
        <div className={GifTile}>
        <img src={EPODETILE} style={{backgroundSize:"contain", width: "80vw", paddingTop: "25px"}}></img>
        </div>
        <div className={LandingSlider}>
        <Carousel 
        swipeable={false}
        draggable={false}
        slidesToSlide={1}
        responsive={responsive}
        autoPlay={false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        >
      <div style={{height: "100vh",display: "flex", position: "relative",paddingTop: "35px"}}>
        <img src={EPODE} style={{backgroundSize:"contain", height: "100vh", width: "100vw"}}></img>
      </div>
    </Carousel>
        </div>
      <div className={Wrapper}>
      <br />
      <div className={CacheCache}>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "60px", lineHeight: "77px", fontWeight: "bold"}}>EPODE</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Ana Monsó, Mathias Bensimon</p>
            <p style={{fontSize: "16px", lineHeight: "21px", margin: "9px 0 0"}}>DUO SHOW</p>
          </div>
          <div style={{display: "flex"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", marginBottom: "30px", fontWeight: "bold"}}>
            Prolongation jusqu'au 3 juin!
            </p>
          </div>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", margin: "0 0 19px", fontWeight: "bold"}}>PARIS</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>62 RUE DU FAUBOURG SAINT-HONORÉ</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>75008 PARIS FRANCE</p>
          </div>
          </div>
        </div>
        <div style={{display: "flex",width: "50%", paddingBottom: "40px", paddingLeft: "50px"}}>
          {/* <a href="/fr/contactez-nous">
            <p style={{textDecoration: "underline", fontWeight: "bold"}}>CATALOGUE SUR DEMANDE</p>
          </a> */}
          <a href={CP} style={{textDecoration: "none"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>COMMUNIQUÉ DE PRESSE</p></a>
          <a href="/fr/contactez-nous" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>CONTACT</p></a>
          <a href="/fr/visite-virtuelle-epode" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VISITE VIRTUELLE</p></a>
        </div>
      </div>
        <div className={VirtualMobile}>
          <a href="/fr/visite-virtuelle-epode" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VISITE VIRTUELLE</p></a>
        </div>

        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}}>
        Epode conjugue l’art du post-graffiti avec la dualité d’un diptyque poétique ; un chant des arts éphémère, un miroir, où l’ombre se transforme en lumière, et où l’empreinte se fait la marque de l’intime. Deux mondes, deux temps, moirent ici l’imaginaire d’Ana Monsó et de Mathias Bensimon ; et interpellent le regard du spectateur.
Epode se fait alors réponse, fragmentaire, où l’espace d’exposition devient une tangibilia, un objet sensible, le prisme à travers lequel se meut la substantifique lumière de l'oeuvre.
C’est là que se déploie la poétique d’Ana Monsó et de Mathias Bensimon. Ils interrogent les variations de l'oeuvre, et la relation qu’elle entretient avec l’espace et la lumière ; entités consubstantielles de l’objet art.
Au sein de cette exposition, les toiles présentées par Ana Monsó obligent à questionner le message de l'oeuvre, à y chercher le singulier, la nuance ; comme le regard solitaire qui balaie la vie urbaine,
Ana oblige celui du spectateur à prendre le temps, à s’arrêter sur les signes cachés d’une présence mémorielle; symbolique où surgit la vision poétique : des lettres, des mots, qui dévoilent la métaphore du souvenir, à peine visible, un ça-a-été.
          </p>
        <br />
        </div>
        <div className={QuoteWrapper}>
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
            <p style={{fontWieight: "bold"}}>Que dit le titre EPODE sur votre travail et votre façon de peindre ? Que signifie-t-il pour vous ?</p>
            <br />
            <p className={Quote}>
            Dans mes oeuvres il y a une coexistence de deux mondes séparés, rendus visibles en un seul : le monde rationnel et le monde poétique. Ce que l'on y voit sont des réinterprétations abstraites nostalgiques de mes propres souvenirs, réinterprétations qui se résolvent en deux étapes. La première est l'étape rationnelle, où je réinterprète mes souvenirs en dessinant librement ce que le moment que je fais revivre me suggère. La seconde est l'étape poétique, dans laquelle les symboles créés pendant l'étape "rationnelle" sont recouverts d'une couche de peinture. En ajoutant la peinture librement, je ne sais pas ce qui sera visible ou non une fois qu'elle aura séché. C'est une métaphore de ce qui reste visible de ce souvenir maintenant en moi, car certains éléments du souvenir ont été effacés, comme dans la peinture. Les deux étapes, ou en d'autres termes, les deux mondes (poétique et rationnel) coexistent et travaillent ensemble pour créer une pièce finale, une chanson, qui mènent à un monde nouveau ; tout comme dans un Epode où deux vers inégaux forment le couplet lyrique final. Ana Monsó
            </p>
          </div>
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
          <p style={{fontWieight: "bold"}}>Que dit le titre EPODE sur votre travail et votre façon de peindre ? Que signifie-t-il pour vous ?</p>
          <br />
            <p className={Quote}>
            Pour évoquer ce que le mot épode à fait naître en moi, je citerais Horace : "Mais moi, pour qui la vie n’a de charme que toi
vivant, sinon insupportable ?
Tu me dis de poursuivre dans mon loisir.
Le ferai-je ? Il n’est doux qu’avec toi."
Afin de provoquer une réflexion existentielle, je souhaiterais aborder la question : Par quoi est-on animé ?
L'énigme de la création a toujours été sublimée par une dichotomie existentielle, équilibre subtil entre évanescence et intemporalité. C'est en effet que notre perception du monde se fonde sur trois éléments constitutifs, la matière, l'énergie et la connaissance (l’information). Ainsi, une création artistique se trouve elle-même teintée de ces principes, en incorporant la matière, issue de notre quotidien, l’énergie, incarnée par le geste, le mouvement et l'action, ainsi que l’intelligence affective ou sensorielle, la tonalité émotionnelle qui émane de l'oeuvre et l'expérience qu'elle suscite.
S'apparentant à une nécessité fondamentale pour l'être humain, elle est pourtant dépourvue de toute utilité pratique, portant en elle-même ce paradoxe qui fait d'elle à la fois une manifestation spirituelle (de l’esprit) et physique (en matière) en induisant une expérience sensible. Mathias Bensimon
              </p>
          </div>
        </div>
        
        <div className={Wrapper}>
        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}} >
          Une individuation que l’on retrouve à travers les peintures de Mathias Bensimon, pour qui la lumière a une matérialité propre, une consistance. Il fait d’elle l’architecte, le démiurge, qui, par le mouvement, donne vie aux différentes teintes qui dansent sous les yeux du spectateur. Cette multitude de caractères individués donne à ses oeuvres la libre interprétation de leur poésie, ce qu’il nomme “l’espace” ; relation intime entre la subjectivité du sujet regardant et celle de l’objet regardé. Deux artistes singuliers sont ici présentés par Ghost galerie. Deux artistes qui, au regard de l’art urbain du graffiti et du post-graffiti, épousent une approche, sinon similaire, du moins concomitante d’une nécessité de l’abandon de la structure formelle et conceptuelle, et ce, dans un désir accru d’éveiller la sensibilité subjective du spectateur. Léon Vuillecard
          </p>
        <br />
        <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              // style={{height: "450px"}}
              src={ANA}
            />
            </div>
          </div>
          <div className={ArtistBio}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Ana Monsó</p>
            <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1998 à Barcelone, Espagne</p>
            <p className={ArtistBioText}>
            Etudiant à Cambridge (Art and Design) puis à Londres (Fashion Design at the University of the Arts), Ana Monsó se définit comme une artiste multidisciplinaire. Sa pratique artistique véhicule un esprit spontané s'inspirant de ses souvenirs d'enfance. "Quand je serai grande, je veux redevenir un enfant." Elle est actuellement l'une des artistes résidentes du programme d’art contemporain Piramidón à Barcelone aux côtés d’une vingtaine d’autres artistes et vient d’être sélectionnée pour intégrer la Royal Academy of Art de Londres à la rentrée 2023.
              </p>
              <br />
            <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueAna}>Télécharger le catalogue des oeuvres</a>
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresANA.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>
                <img
                  className={ImageWrapper}
                  style={{objectFit: "contain"}}
                  // key={image.name}
                  // alt={image.name}
                  src={image.src}
                />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
          <div className={Atelier}>
          <Carousel 
              swipeable={false}
              draggable={false}
              slidesToSlide={1}
              responsive={responsive}
              autoPlay={false}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              partialVisible={false}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              >
            <div style={{height: "75vh",display: "flex",marginTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center",}}>
                <img src={AnaAtelier1} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src={AnaAtelier2} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
                </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={AnaAtelier3} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
          </Carousel>
          </div>

        </div>
      </div>
      </div>
      <div className={Wrapper}>
        <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{width: "300px", height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              style={{objectPosition: "0 0"}}
              src={MATHIAS}
            />
            </div>
          </div>
          <div className={ArtistBio} style={{paddingTop: "100px"}}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Mathias Bensimon</p>
            <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1996 à Paris, France</p>
            <p className={ArtistBioText}>
            Diplômé des Beaux-Arts de Paris en 2022, Mathias Bensimon est un artiste français, plasticien, performeur et danseur. Durant sa formation, il intègre l’atelier d’Ann Veronica Janssens, célèbre plasticienne belge qui a beaucoup questionné les effets de transparences et de lumières et avec qui il a pu étudier le rapport entre l’expérimentation et les perceptions. Mathias exposera au Musée d'Orsay en mars prochain dans le cadre de l'exposition "Pastels, de Millet à Redon” du 14 mars au 2 juillet. En octobre 2022, il participe à l'exposition organisée par Lawrence Van Hagen  "California Light & Space & Beyond" chez Paulin à Paris. Depuis 2019, il a également collaboré avec le Musée du Grand Palais à Paris dans le cadre de l’EuroFabrique, le Museu Arqueologico do Carmo, le Museum of Fine arts, le Ho Chi Minh City, le Temple de Hesadera, le Tokyo Musashino museum ainsi qu’avec le Château de la Colle Noire où il participe au prix Dior.</p>
          <br />
          <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueMathias}>Télécharger le catalogue des oeuvres</a>
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresMATHIAS.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>

                  <img
                    className={ImageWrapper}
                    style={{objectFit: "contain"}}
                    // key={image.name}
                    // alt={image.name}
                    src={image.src}
                  />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
        <div style={{paddingTop: "50px"}}>
          <div className={Atelier}>

          <Carousel 
        swipeable={false}
        draggable={false}
        slidesToSlide={1}
        responsive={responsive}
        autoPlay={false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        >
      <div style={{height: "75vh",display: "flex", position: "relative"}}>
        <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <img src={MathiasAtelier1} style={{objectFit:"cover", height: "100vh", objectPosition: "0 0", marginTop: "400px"}}></img>
        </div>
      </div>
      <div style={{height: "75vh",display: "flex", position: "relative"}}>
        <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={MathiasAtelier2} style={{objectFit:"cover", height: "100vh", objectPosition: "0 0", marginTop: "400px"}}></img>
          </div>
      </div>
      <div style={{height: "75vh",display: "flex", position: "relative"}}>
        <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <img src={MathiasAtelier3} style={{objectFit:"cover", height: "100vh", objectPosition: "0 0", marginTop: "400px"}}></img>
        </div>
      </div>
    </Carousel>
    </div>
    </div>
    </div>
    </div>
    </div>

    <div className={QuoteWrapper}>
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
            <p style={{fontWieight: "bold"}}>QUAND JE SERAI "GRANDE", JE VEUX REDEVENIR UN ENFANT</p>
            <br />
            <p className={Quote}>Je centre mon travail sur l'auto-réflexion des moments sacrés de ma vie. Puisant dans mes souvenirs d'enfance, je crée librement, permettant à l'oeuvre finale d'être une fusion de mes inspirations. Mon travail est une ode à l'innocence enfantine latente. J'essaie de réinterpréter le monde sans règles et sans limites, comme le ferait un enfant avant que le jugement social et les normes établies ne lui soient imposées. Ana Monsó</p>
          </div>
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
            <p  style={{fontWieight: "bold"}}>STATEMENT</p>
            <br />
            <p className={Quote}>Dans le champ de ma pratique, la lumière constitue le coeur de mon travail. La lumière en tant que médium et substance, mais aussi la lumière en tant qu’intelligence révélatrice. Tel un témoignage des différentes manifestations du réel, la lumière peut nous apparaître sous une multitude de formes et de réflexion. Elle pose alors la question du visible et de l’invisible. Comment rendre visible l’invisible ? Comment toucher la source immatérielle en passant par la matière ? Mathias Bensimon</p>
          </div>
        </div>

         
      <div className={Wrapper}>
      <div className={Atelier}>
          <Carousel 
              swipeable={false}
              draggable={false}
              slidesToSlide={1}
              responsive={responsive}
              autoPlay={false}
              autoPlaySpeed={2000}
              keyBoardControl={true}
              partialVisible={false}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              >
            <div style={{height: "75vh",display: "flex",marginTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center",}}>
                <img src={SITUATION1} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <img src={SITUATION2} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
                </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={SITUATION3} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={SITUATION4} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
            <div style={{height: "75vh",display: "flex",paddingTop: "35px"}}>
              <div style={{height: "50vh", width: "100vw", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={SITUATION5} style={{objectFit:"contain", height: "91vh", objectPosition: "0 0", marginTop: "400px"}}></img>
              </div>
            </div>
          </Carousel>
          </div>
      </div>
      <br />
          <br />
          <br />
      </div> 
  );
};

export default Epode;